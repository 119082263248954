import dynamic from "next/dynamic";
import { VimeoPlayerProps } from "react-player/vimeo";
import { YouTubePlayerProps } from "react-player/youtube";

export type DynamicPlayerProps = (VimeoPlayerProps | YouTubePlayerProps) & {
  ssr?: boolean,
  YTNoCookie?: boolean,
};


/**
 * Checks a url to test if it is from youtube.
 *
 * @param url
 */
export function isYoutubeURL (url: string): boolean {
  const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  return pattern.test(url);
}

export function DynamicVideoPlayer({...props}: DynamicPlayerProps ) {
  const ssr = props?.ssr ?? false;
  const isYouTube = typeof(props?.url) === "string" && isYoutubeURL(props?.url ?? "");

  if (props?.ssr) delete(props.ssr);

  // Handle YouTube No-Cookie videos.
  if (isYouTube && typeof props.url === 'string') {
    if (props?.url?.match(/https\:\/\/www.youtube.com/)) {
      props.url = props.url.replace('https://www.youtube.com/', 'https://www.youtube-nocookie.com/');
    }
  }

  const PlayerComp = dynamic<DynamicPlayerProps>(() => isYouTube
      ? import("react-player/youtube")
      : import("react-player/vimeo"),
    { ssr });

  return <PlayerComp {...props} />
}

